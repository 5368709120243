<template>
<!-- 商标在线自主注册页 -->
  <div class="index">
  <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

  <div class="bodyBox">
    <img class="background" src="../imger/success/banner.png" />
    <div class="content">

      <!-- 广告-商标注册介绍 -->
      <div class="AD">
        <div class="AD01">
          <div class="AD01box">
              <div class="AD01box01" >超6000万商标大数据</div>
              <div class="AD01box02">手机扫码自主申报</div>
              <div class="AD01box03">
                <div class="picture"></div>
                <p>扫码体验小程序</p>
              </div>
              <div class="AD01box04">免费查询商标</div>
              <div class="AD01box05">专业客服在线服务</div>
          </div>
        </div>
      </div>
    </div>
    <div class="AD02">
      <div class="AD02Content">
        <div class="AD02ContentBox">
          <img class="AD02ContentBoxPicture" src="../imger/success/icon1.png"/>
          <a href="" class="AD02ContentBoxWord">商标查询助手</a>
        </div>
        <div class="AD02ContentBox">
          <img class="AD02ContentBoxPicture" src="../imger/success/icon2.png"/>
          <a href="" class="AD02ContentBoxWord">商标在线注册</a>
        </div>
        <div class="AD02ContentBox">
          <img class="AD02ContentBoxPicture" src="../imger/success/icon3.png"/>
          <a href="" class="AD02ContentBoxWord">商标在线认领</a>
        </div>
        <div class="AD02ContentBox">
          <img class="AD02ContentBoxPicture" src="../imger/success/icon4.png"/>
          <a href="" class="AD02ContentBoxWord">商标智能监测</a>
        </div>
        <div class="AD02ContentBox">
          <img class="AD02ContentBoxPicture" src="../imger/success/icon5.png"/>
          <a href="" class="AD02ContentBoxWord">商标管理工具</a>
        </div>
      </div>
    </div>
    <div class="AD03">
      <div class="AD03Content">
        <div class="AD03ContentBox01">
          <div class="AD03ContentBox01Gou">
            <img src="../imger/success/gou.png" class="AD03ContentBox01GouPocture" alt="">
            <a href="" class="AD03ContentBox01GouWord">国家商标局备案机构</a>
          </div>
          <div class="AD03ContentBox01Gou">
            <img src="../imger/success/gou.png" class="AD03ContentBox01GouPocture" alt="">
            <a href="" class="AD03ContentBox01GouWord">国家商标局备案机构</a>
          </div>
          <div class="AD03ContentBox01Gou">
            <img src="../imger/success/gou.png" class="AD03ContentBox01GouPocture" alt="">
            <a href="" class="AD03ContentBox01GouWord">国家商标局备案机构</a>
          </div>
        </div>
        <div class="AD03ContentBox02">
          <a href="" class="AD03ContentBox02Word">多种服务随心选</a>
        </div>
        <div class="AD03ContentBox03">
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon1.png" alt="" class="AD03icon">
            <a href="" class="AD03ContentBox03BoxWord">商标注册</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon2.png" alt="" class="AD03icon2">
            <a href="" class="AD03ContentBox03BoxWord">商标转让</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon3.png" alt="" class="AD03icon3">
            <a href="" class="AD03ContentBox03BoxWord">商标免费查询</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon4.png" alt="" class="AD03icon4">
            <a href="" class="AD03ContentBox03BoxWord">商标设计</a>
          </div>

          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon5.png" alt="" class="AD03icon5">
            <a href="" class="AD03ContentBox03BoxWord">商标变更</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon6.png" alt="" class="AD03icon6">
            <a href="" class="AD03ContentBox03BoxWord">商标续展</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon7.png" alt="" class="AD03icon7">
            <a href="" class="AD03ContentBox03BoxWord">商标驳回复审</a>
          </div>
          <div class="AD03ContentBox03Box">
            <img src="../imger/success/AD03icon8.png" alt="" class="AD03icon8">
            <a href="" class="AD03ContentBox03BoxWord">商标异议答辩</a>
          </div>
        </div>
      </div>
    </div>
 </div>
  <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />  
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Sidebar from '../components/sidebar.vue'
import Eject from '../components/eject.vue'
import Drawer from '../components/drawer.vue'
import ColumnSearch from '../components/columnSearch.vue'
import TradHeader from '../components/trademarkClassification/tradHeader.vue'

import Title from '../components/trademarkRegistration/title.vue'

export default {

  components:{
    Sidebar,Footer,Header,Drawer,Eject,ColumnSearch,
    TradHeader,Title
  },
  data(){
    return {
      titile:[
        {
          word01:"Brand",
          word02:"为什么要注册",
          word03:"商标",
        },
        {
          word01:"Unfazeable",
          word02:"商标注册",
          word03:"无忧",
        },
        {
          word01:"rocess ",
          word02:"商标注册",
          word03:"流程",
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  .bodyBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .background {
      position: absolute;
      width: 100%;
      height: 37rem /* 592px -> 37rem */;
      z-index: -1;
    }
    .content {
      width: 75rem /* 1200px -> 75rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 27.0625rem /* 433px -> 27.0625rem */;
    
      .AD {
        width: 100%;
        margin-top: 2.125rem /* 34px -> 2.125rem */;
        .AD01 {
          width: 100%;
          height:42.875rem /* 686px -> 42.875rem */;
          background: #fff;
          background-image: url("../imger/success/bg.png");
          background-size: 100% 100%;
          box-shadow: 0 2px 5px 2px rgba(112, 112, 112 , .4);
          border-radius: 10px;
          .AD01box {
            width: 100%;
            height: 15.625rem /* 250px -> 15.625rem */;
            position: relative;
            .AD01box01 {
              width: 12.1875rem /* 195px -> 12.1875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/1.png");
              position: absolute;
              top: 3.875rem /* 62px -> 3.875rem */;
              left: 18.125rem /* 290px -> 18.125rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #fff;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box02 {
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group01.png");
              position: absolute;
              top: 10.1875rem /* 163px -> 10.1875rem */;
              left: 22.5625rem /* 361px -> 22.5625rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #4D89F1;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box03 {
              height: 9.6875rem /* 155px -> 9.6875rem */;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              position: absolute;
              top: 2.6875rem /* 43px -> 2.6875rem */;
              left: 33.9375rem /* 543px -> 33.9375rem */;
              font-size: 1rem /* 16px -> 1rem */;
              color: #333333;
              .picture {
                width: 7.9375rem /* 127px -> 7.9375rem */;
                height: 7.9375rem /* 127px -> 7.9375rem */;
                background-image: url("../imger/wbwxcx.png");
                background-size: 100% 100%;
              }
            }
            .AD01box04 {
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group01.png");
              position: absolute;
              top: 3.5rem /* 56px -> 3.5rem */;
              right: 19.125rem /* 306px -> 19.125rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #4D89F1;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
            .AD01box05{ 
              width: 9.6875rem /* 155px -> 9.6875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
              background-image: url("../imger/tranResg/group03.png");
              position: absolute;
              top: 10.125rem /* 162px -> 10.125rem */;
              right: 20.0625rem /* 321px -> 20.0625rem */;
              background-size: 100% 100%;
              font-size: 1rem /* 16px -> 1rem */;
              color: #fff;
              text-align: center;
              line-height: 2.6875rem /* 43px -> 2.6875rem */;
            }
          }
        }

      }
     
    }
    .AD02{
      width: 100%;
      height: 20.6875rem /* 331px -> 20.6875rem */;
      background-color: #F6F6F6;
      display: flex;
      justify-content: center;
      .AD02Content{
        width: 75rem /* 1200px -> 75rem */;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .AD02ContentBox{
          width: 13.875rem /* 222px -> 13.875rem */;
          height: 12.5rem /* 200px -> 12.5rem */;
          box-shadow: 0 2px 5px 2px rgba(112, 112, 112 , .1);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .AD02ContentBoxPicture{
            width: 5.875rem /* 94px -> 5.875rem */;
            height: 5.875rem /* 94px -> 5.875rem */;
          }
          .AD02ContentBoxWord{
            color: #333333;
            font-size: 1.25rem /* 20px -> 1.25rem */;
          }
        }
      }
    }
    .AD03{
      width: 100%;
      height: 32.375rem /* 518px -> 32.375rem */;
      display: flex;
      justify-content: center;
      align-items: center;
      .AD03Content{
        width: 75rem /* 1200px -> 75rem */;
        height: 25.75rem /* 412px -> 25.75rem */;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .AD03ContentBox01{
          width: 68.125rem /* 1090px -> 68.125rem */;
          height: 5.625rem /* 90px -> 5.625rem */;
          border-bottom: 0.0625rem /* 1px -> 0.0625rem */solid #CCCCCC;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .AD03ContentBox01Gou{
            height: 100%;
            display: flex;
            align-items: center;
            .AD03ContentBox01GouPocture{
              width: 1.875rem /* 30px -> 1.875rem */;
              height: 1.875rem /* 30px -> 1.875rem */;
              margin-right: 1.25rem /* 20px -> 1.25rem */;

            }
            .AD03ContentBox01GouWord{
              font-size: 1.25rem /* 20px -> 1.25rem */;
              color: #666666;
            }
          }
        }
        .AD03ContentBox02{
          width: 46rem /* 736px -> 46rem */;
          height: 4.8125rem /* 77px -> 4.8125rem */;
          background-image: url("../imger/success/hunter_title.png");
          background-size: contain;
          display: flex;
          justify-content: center;
          .AD03ContentBox02Word{
            font-size: 1.875rem /* 30px -> 1.875rem */;
            color: #4D89F1;
          }
        }
        .AD03ContentBox03{
          width: 100%;
          height: 9.375rem /* 150px -> 9.375rem */;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .AD03ContentBox03Box{
            height: 8.125rem /* 130px -> 8.125rem */;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .AD03icon{
              width: 4.4375rem /* 71px -> 4.4375rem */;
              height: 4rem /* 64px -> 4rem */;
            }
            .AD03icon2{
              width: 4.5rem /* 72px -> 4.5rem */;
              height:4.125rem /* 66px -> 4.125rem */;
            }
            .AD03icon3{
              width: 4.6875rem /* 75px -> 4.6875rem */;
              height: 4.0625rem /* 65px -> 4.0625rem */;
            }
            .AD03icon4{
              width: 4.4375rem /* 71px -> 4.4375rem */;
              height: 4.5rem /* 72px -> 4.5rem */;
            }
            .AD03icon5{
              width: 4.1875rem /* 67px -> 4.1875rem */;
              height: 3.9375rem /* 63px -> 3.9375rem */;
            }
            .AD03icon6{
              width: 4.3125rem /* 69px -> 4.3125rem */;
              height: 4rem /* 64px -> 4rem */;
            }
            .AD03icon7{
              width: 4.3125rem /* 69px -> 4.3125rem */;
              height: 3.5625rem /* 57px -> 3.5625rem */;
            }
            .AD03icon8{
              width: 3.5rem /* 56px -> 3.5rem */;
              height: 3.875rem /* 62px -> 3.875rem */;
            }
            
            .AD03ContentBox03BoxWord{
              color: #666666;
              font-size: 1rem /* 16px -> 1rem */;
            }
          }
        }
      }
    }
  }
}
</style>