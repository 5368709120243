import { render, staticRenderFns } from "./success.vue?vue&type=template&id=488a309c&scoped=true&"
import script from "./success.vue?vue&type=script&lang=js&"
export * from "./success.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./success.vue?vue&type=style&index=0&id=488a309c&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "488a309c",
  "576137b9"
  
)

export default component.exports